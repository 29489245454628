import { push } from "../../util/Utils";
import { entityListKeyMapping } from "../reducer/CurrentListActionReducer";
import { isChannelAdmin, isContentAdmin } from "./LoginActions";
import {
  ModalName,
  showErrorMessageBarAction,
  showModalAction,
  showLeftDrawerAction,
} from "./UIActions";

export const gettingStartedRideAction = (rideKey, stepIndex = 0) => {
  return {
    type: "gettingStartedRide",
    value: rideKey ? { rideKey, stepIndex } : null,
  };
};

export const gettingStartedRideDispatcher = (rideKey) => {
  let content, channel;
  return (dispatch, getState) => {
    switch (rideKey) {
      case "topicNotifications":
        push("/myProfile/keywords");
        break;
      case "topicDetection":
        content = findVideoWithEntityMeta(getState);
        if (content) push(`/video/${content?.id}`);
        else {
          dispatch(showErrorMessageBarAction(true, "First upload a video 😉"));
          return;
        }
        break;
      case "summarization":
        push("/account/ai");
        break;
      case "actionItems":
        push("/account/ai");
        break;
      case "watercooler":
        push("/account/ai");
        break;
      case "actionItems":
        push("/account/ai");
        break;
      case "slackIntegration":
        channel = findChannelWithAdmin(getState);
        if (channel) push(`/channel/${channel?.id}`);
        else {
          dispatch(
            showErrorMessageBarAction(true, "First create a channel 😉")
          );
          return;
        }
        break;
      case "zoomIntegration":
        push("/myProfile/integrations");
        break;
      case "notificationPreferences":
        push("/myProfile/preferences");
        break;
      case "transcripts":
        content =
          findVideoWithTranscriptMetaAndAdmin(getState) ||
          findVideoWithTranscriptMeta(getState);
        if (content) push(`/video/${content?.id}`);
        else {
          dispatch(showErrorMessageBarAction(true, "First upload a video 😉"));
          return;
        }
        break;
      case "sharing":
        content = findVideoWithAdmin(getState);
        if (content) push(`/video/${content?.id}`);
        else {
          dispatch(showErrorMessageBarAction(true, "First upload a video 😉"));
          return;
        }
        break;
      case "profilePage":
        push("/");
        dispatch(showLeftDrawerAction(true));
        break;
      case "analytics":
        content = findVideoWithAdmin(getState);
        if (content) push(`/video/${content?.id}`);
        else {
          dispatch(showErrorMessageBarAction(true, "First upload a video 😉"));
          return;
        }
        break;
      case "security":
        push("/account/settings");
        break;
      default:
        push("/");
    }
    dispatch(showModalAction(ModalName.GettingStartedModal, false));
    dispatch(gettingStartedRideAction(rideKey));
  };
};

const findVideoWithEntityMeta = (getState) => {
  return getCurrentContent(getState).find(
    (content) =>
      content.contentType === "VIDEO" && !!content.entityContentMetaUrl
  );
};

const findVideoWithTranscriptMeta = (getState) => {
  return getCurrentContent(getState).find(
    (content) =>
      content.contentType === "VIDEO" && !!content.transcriptContentMetaUrl
  );
};

const findVideoWithTranscriptMetaAndAdmin = (getState) => {
  const { account, userSession } = getState().current.entity;
  const accountUser = userSession?.accountUser;
  return getCurrentContent(getState).find(
    (content) =>
      content.contentType === "VIDEO" &&
      !!content.transcriptContentMetaUrl &&
      isContentAdmin(accountUser, content, null, account)
  );
};

const findVideoWithAdmin = (getState) => {
  const { account, userSession } = getState().current.entity;
  const accountUser = userSession?.accountUser;
  return getCurrentContent(getState).find(
    (content) =>
      content.contentType === "VIDEO" &&
      isContentAdmin(accountUser, content, null, account)
  );
};

const getCurrentContent = (getState) => {
  const contentList = [];
  const { list } = getState().current;
  for (let key in entityListKeyMapping.content) {
    if (list[key]) contentList.push(...list[key]);
  }
  return contentList;
};

const findChannelWithAdmin = (getState) => {
  const { account, userSession } = getState().current.entity;
  const accountUser = userSession?.accountUser;
  return getCurrentChannel(getState).find((channel) =>
    isChannelAdmin(accountUser, channel, account)
  );
};

const getCurrentChannel = (getState) => {
  const channelList = [];
  const { list } = getState().current;
  for (let key in entityListKeyMapping.channel) {
    if (list[key]) channelList.push(...list[key]);
  }
  return channelList;
};
