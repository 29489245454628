import {
  Card,
  CardActionArea,
  CardActions,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React from "react";
import { RecorderOptions } from "../../redux/action/RecorderActions";
import CloseIcon from "@mui/icons-material/Close";
import NoneIcon from "@mui/icons-material/BrowserNotSupportedOutlined";

export const backgroundGradientList = {
  none: {
    name: "None",
    type: "linear",
    colors: [
      {
        0: "#fff",
      },
    ],
  },
  white: {
    name: "White",
    type: "linear",
    colors: [
      {
        0: "#fff",
      },
      {
        1: "#fff",
      },
    ],
  },
  "lite-grey": {
    name: "Platinum",
    type: "linear",
    colors: [
      {
        0: "#eee",
      },
      {
        0.99: "#ddd",
      },
      {
        1: "#aaa",
      },
    ],
  },
  "blue-purple": {
    name: "Blue Note",
    type: "linear",
    colors: [
      {
        0: "#450b7c",
      },
      {
        0.5: "#563cc9",
      },
      {
        1: "#4191ff",
      },
    ],
  },
  "warm-flame": {
    name: "Flame",
    type: "linear",
    colors: [
      {
        0: "#ff9a9e",
      },
      {
        0.99: "#fad0c4",
      },
      {
        1: "#fad0c4",
      },
    ],
  },
  "night-fade": {
    name: "Wishes",
    type: "linear",
    colors: [
      {
        0: "#a18cd1",
      },
      {
        1: "#fbc2eb",
      },
    ],
  },
  "sunny-morning": {
    name: "Sunday Morning",
    type: "linear",
    colors: [
      {
        0: "#f6d365",
      },
      {
        1: "#fda085",
      },
    ],
  },
  "tempting-azure": {
    name: "Temptation",
    type: "linear",
    colors: [
      {
        0: "#84fab0",
      },
      {
        1: "#8fd3f4",
      },
    ],
  },
  "amy-crisp": {
    name: "Crisp",
    type: "linear",
    colors: [
      {
        0: "#a6c0fe",
      },
      {
        1: "#f68084",
      },
    ],
  },
  "heavy-rain": {
    name: "Rain",
    type: "linear",
    colors: [
      {
        0: "#cfd9df",
      },
      {
        1: "#e2ebf0",
      },
    ],
  },
  "mean-fruit": {
    name: "Fruity",
    type: "linear",
    colors: [
      {
        0: "#fccb90",
      },
      {
        1: "#d57eeb",
      },
    ],
  },
  "malibu-beach": {
    name: "Malibu",
    type: "linear",
    colors: [
      {
        0: "#4facfe",
      },
      {
        1: "#00f2fe",
      },
    ],
  },
  "deep-blue": {
    name: "No 5",
    type: "linear",
    colors: [
      {
        0: "#e0c3fc",
      },
      {
        1: "#8ec5fc",
      },
    ],
  },
  "ripe-malin": {
    name: "Ripe",
    type: "linear",
    colors: [
      {
        0: "#f093fb",
      },
      {
        1: "#f5576c",
      },
    ],
  },
  "arielle-smile": {
    name: "Smile",
    type: "linear",
    colors: [
      {
        0: "#16d9e3",
      },
      {
        0.47: "#30c7ec",
      },
      {
        1: "#46aef7",
      },
    ],
  },
  "plum-plate": {
    name: "Plum",
    type: "linear",
    colors: [
      {
        0: "#667eea",
      },
      {
        1: "#764ba2",
      },
    ],
  },
  "happy-fisher": {
    name: "Fisher",
    type: "linear",
    colors: [
      {
        0: "#89f7fe",
      },
      {
        1: "#66a6ff",
      },
    ],
  },
  "happy-itmeo": {
    name: "Irie",
    type: "linear",
    colors: [
      {
        0: "#2af598",
      },
      {
        1: "#009efd",
      },
    ],
  },
  "mixed-hopes": {
    name: "Hello Kitty",
    type: "linear",
    colors: [
      {
        0: "#c471f5",
      },
      {
        1: "#fa71cd",
      },
    ],
  },

  "strong-bliss": {
    name: "Bliss",
    type: "linear",
    colors: [
      {
        0: "#f78ca0",
      },
      {
        0.19: "#f9748f",
      },
      {
        0.6: "#fd868c",
      },
      {
        0.1: "#fe9a8b",
      },
    ],
  },
  "grow-early": {
    name: "Growth",
    type: "linear",
    colors: [
      {
        0: "#0ba360",
      },
      {
        1: "#3cba92",
      },
    ],
  },
  "bg-love-kiss": {
    name: "Kiss",
    type: "linear",
    colors: [
      {
        0: "#ff0844",
      },
      {
        1: "#ffb199",
      },
    ],
  },
  "premium-dark": {
    name: "Blackout",
    type: "linear",
    colors: [
      {
        0: "#434343",
      },
      {
        1: "#000",
      },
    ],
  },
  "happy-green": {
    name: "Lucky",
    type: "linear",
    colors: [
      {
        0: "#00b09b",
      },
      {
        1: "#96c93d",
      },
    ],
  },
  "vicious-stance": {
    name: "Assertive",
    type: "linear",
    colors: [
      {
        0: "#29323c",
      },
      {
        1: "#485563",
      },
    ],
  },
  "midnight-bloom": {
    name: "Midnight",
    type: "linear",
    colors: [
      {
        0: "#2b5876",
      },
      {
        1: "#4e4376",
      },
    ],
  },
  "night-sky": {
    name: "Night Sky",
    type: "linear",
    colors: [
      {
        0: "#1e3c72",
      },
      {
        0.1: "#1e3c72",
      },
      {
        1: "#2a5298",
      },
    ],
  },
  "slick-carbon": {
    name: "Carbon Fiber",
    type: "linear",
    colors: [
      {
        0: "#323232",
      },
      {
        0.4: "#3F3F3F",
      },
      {
        1: "#1C1C1C",
      },
    ],
  },
  royal: {
    name: "Royals",
    type: "linear",
    colors: [
      {
        0: "#141e30",
      },
      {
        1: "#243b55",
      },
    ],
  },
  asteroid: {
    name: "Space Ghost",
    type: "linear",
    colors: [
      {
        0: "#0f2027",
      },
      {
        0.5: "#203a43",
      },
      {
        1: "#2c5364",
      },
    ],
  },
  "skim-blue": {
    name: "Clarity",
    type: "linear",
    colors: [
      {
        0.1: "#ABDCFF",
      },
      {
        1: "#0396FF",
      },
    ],
  },
  "light-pure": {
    name: "En Pure",
    type: "linear",
    colors: [
      {
        0.1: "#CE9FFC",
      },
      {
        1: "#7367F0",
      },
    ],
  },
  "nice-redora": {
    name: "Sincere",
    type: "linear",
    colors: [
      {
        0.1: "#F761A1",
      },
      {
        1: "#8C1BAB",
      },
    ],
  },
  "red-lights": {
    name: "Warmth",
    type: "linear",
    colors: [
      {
        0: "#F05F57",
      },
      {
        1: "#360940",
      },
    ],
  },
  "serious-blue": {
    name: "Straight Business",
    type: "linear",
    colors: [
      {
        0.1: "#97ABFF",
      },
      {
        1: "#123597",
      },
    ],
  },
  "deep-sky": {
    name: "Sky",
    type: "linear",
    colors: [
      {
        0.1: "#6B73FF",
      },
      {
        1: "#000DFF",
      },
    ],
  },
  "sunrise-purple": {
    name: "Lovely",
    type: "linear",
    colors: [
      {
        0.1: "#3B2667",
      },
      {
        1: "#BC78EC",
      },
    ],
  },
};

export const getBackgroundSize = (bounds) => {
  const aspectRatio = 1.778;
  if (bounds.width < bounds.height * aspectRatio)
    return {
      width: bounds.width,
      height: bounds.width / aspectRatio,
    };
  else
    return {
      width: bounds.height * aspectRatio,
      height: bounds.height,
    };
};

export const getBackgroundSizeStyle = (bounds) => {
  const backgroundSize = getBackgroundSize(bounds);
  return {
    height:
      typeof bounds.height !== "number"
        ? "100%"
        : `${getBackgroundSize(bounds).height}px`,
    width:
      typeof bounds.width !== "number"
        ? "100%"
        : `${getBackgroundSize(bounds).width}px`,
    border: "1px solid #cbd5e0",
  };
};

export const getBackgroundGradientStyle = (backgroundGradientName) => {
  if (!backgroundGradientName) return {};
  const backgroundGradient = backgroundGradientList[backgroundGradientName];
  let gradientStyle = `${backgroundGradient.type}-gradient(`;
  for (const colorObject of backgroundGradient.colors) {
    for (const [position, color] of Object.entries(colorObject)) {
      gradientStyle += `${color} ${position * 100}%, `;
    }
  }
  gradientStyle = gradientStyle.substring(0, gradientStyle.length - 2) + ")";
  return {
    "background-image": gradientStyle,
  };
};

const useStyles = makeStyles()({
  colorSwatch: {
    position: "relative",
    transition: "opacity 250ms ease-in",
    borderTopLeftRadius: "0.75rem",
    borderTopRightRadius: "0.75rem",
    height: "84px",
    width: "300px",
    margin: "0 auto",
    "&:hover": {
      cursor: "pointer",
      opacity: 0.65,
    },
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

const RecorderBackgroundChooser = ({
  setBackgroundGradientClassName,
  onClose,
}) => {
  const theme = useTheme();
  const { classes, cx } = useStyles();
  const getBackgroungColorCards = () => {
    const backgroundColorCards = [];
    for (const [className, gradientObject] of Object.entries(
      backgroundGradientList
    )) {
      backgroundColorCards.push(
        <Grid item>
          <Card className={classes.root}>
            <CardActionArea
              onClick={() => setBackgroundGradientClassName(className)}
            >
              <div
                className={classes.colorSwatch}
                style={{
                  ...getBackgroundGradientStyle(className),
                  border: "1px solid #efefef",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {className === "none" && (
                  <NoneIcon
                    style={{
                      color: theme.palette.error.main,
                      fontSize: "2.25rem",
                      opacity: 0.85,
                    }}
                  />
                )}
              </div>
            </CardActionArea>
            <Typography
              variant="subtitle1"
              sx={{ color: theme.palette.grey["500"] }}
            >
              {gradientObject.name}
            </Typography>
          </Card>
        </Grid>
      );
    }
    return backgroundColorCards;
  };
  return (
    <div className="card card-box pb-5">
      <div className={cx("card-header", classes.cardHeader)}>
        <div>
          <h5 className="my-3">Pick a Background Color</h5>
        </div>
        <div>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              onClose && onClose();
            }}
            color="primary"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="divider"></div>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        sx={{ marginTop: "20px" }}
      >
        {getBackgroungColorCards()}
      </Grid>
    </div>
  );
};

export default RecorderBackgroundChooser;
