import "aws-sdk/dist/aws-sdk";
import { v4 as uuidv4 } from "uuid";
import { logError } from "../../service/ServiceUtil";
import { zeroPad } from "../../util/Utils";
/* global window */

const AWS = typeof window !== "undefined" && window.AWS;

/**
 * [sessionId, {status, objectKey, finishedCallbackList[{resolve, reject}], chunkMap: [sequenceNumber, chunkBlob]}]
 * */
const uploadSessionMap = new Map();

const UploadSessionStatus = {
  OPEN: "Available for chunk uploading",
  FINISHED: "No more chunks to be added",
  CLOSED: "All chunks uploaded",
  ERROR: "Chunk upload error",
};

const setAWSCredentials = (userSession) => {
  AWS.config.credentials = new AWS.Credentials(
    userSession.uploadAccessKey,
    userSession.uploadSecretKey,
    null
  );
};

export const startMultipartUploadSession = (fileExtension) => {
  return async (dispatch, getState) => {
    const sessionId = uuidv4();
    uploadSessionMap.set(sessionId, {
      status: UploadSessionStatus.OPEN,
      fileExtension,
      chunkMap: new Map(),
      finishedCallbackList: [],
    });
    const userSession = getState().current.entity.userSession;
    setAWSCredentials(userSession);
    return sessionId;
  };
};

/**
 * Call this once it is time to wait for the upload to finish.
 * @param {*} sessionId
 * @returns A promise which resolves once all the chunks have been uploaded.
 * Once it resolves,  dispatch create upload with sessionId
 * //filename = `${sessionId}.${uploadSession.fileExtension}`,
 */
export const finishMultipartUploadSessionDispatcher = (sessionId) => {
  return async (dispatch, getState) => {
    try {
      const uploadSession = uploadSessionMap.get(sessionId);
      if (uploadSession.status !== UploadSessionStatus.OPEN)
        throw new Error("Upload session not currently Open");
      uploadSession.status = UploadSessionStatus.FINISHED;
      await dispatch(checkUploadSessionFinishedDispatcher(sessionId));
    } catch (e) {
      logError("finishMultipartUploadSessionDispatcher", { sessionId }, e);
      throw Error({
        userMessage: "Sorry.. something went wrong with your upload.",
      });
    }
  };
};

/**
 * Call this to check if the upload has finished or call a callback later.
 * @param {*} sessionId
 * @returns once the upload is finished or throws an exception if there is an error.
 *  calls the uploadSession.finishedCallbackList if it is set
 */
export const checkUploadSessionFinishedDispatcher = (sessionId) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const uploadSession = uploadSessionMap.get(sessionId);
      uploadSession.finishedCallbackList.push({ resolve, reject });
      if (uploadSession.status === UploadSessionStatus.ERROR) {
        while (uploadSession.finishedCallbackList.length > 0)
          uploadSession.finishedCallbackList
            .pop()
            .reject("Upload Session in error state: " + sessionId);
      } else if (
        (uploadSession.status === UploadSessionStatus.FINISHED ||
          uploadSession.status === UploadSessionStatus.CLOSED) &&
        uploadSession.chunkMap.size === 0
      ) {
        uploadSession.status = UploadSessionStatus.CLOSED;
        while (uploadSession.finishedCallbackList.length > 0) {
          const object = uploadSession.finishedCallbackList.pop().resolve();
        }
      }
    });
  };
};

export const queueChunkForUploadDispatcher = (
  sessionId,
  sequenceNumber,
  chunkBlob
) => {
  return async (dispatch, getState) => {
    let objectKey;
    let uploadSession;
    try {
      uploadSession = uploadSessionMap.get(sessionId);
      if (uploadSession.status !== UploadSessionStatus.OPEN)
        throw new Error("Upload session already finished.");
      uploadSession.chunkMap.set(sequenceNumber, chunkBlob);
      const userSession = getState().current.entity.userSession;
      const s3Bucket = getS3Bucket(userSession);
      objectKey = `uploadSession/${sessionId}/${zeroPad(
        sequenceNumber,
        6
      )}.chunk`;
      await uploadChunkFile(chunkBlob, objectKey, s3Bucket);
      uploadSession.chunkMap.delete(sequenceNumber);
      dispatch(checkUploadSessionFinishedDispatcher(sessionId));
    } catch (e) {
      logError(
        "queueChunkForUploadDispatcher",
        { sessionId, sequenceNumber },
        e
      );
      uploadSession.status = UploadSessionStatus.ERROR;
    }
  };
};

const uploadChunkFile = async (chunkBlob, objectKey, s3Bucket) => {
  return new Promise((resolve, reject) => {
    const params = {
      Key: objectKey,
      Tagging: "upload=true",
      ContentType: "application/octet-stream",
      Body: chunkBlob,
    };
    const options = { partSize: 5 * 1024 * 1024, queueSize: 4 };
    s3Bucket.upload(params, options, (err, data) => {
      if (err !== null) {
        logError("uploadChunkFile", { chunkBlob, objectKey, s3Bucket }, err);
        switch (err.code) {
          case "NetworkingError":
            reject({
              userMessage: "Not connected to the Internet",
              errorCode: err.code,
            });
            break;
          case "RequestAbortedError":
            reject({ userMessage: "User cancelled", errorCode: err.code });
            break;
          default:
            reject({ userMessage: "Upload error", errorCode: err.code });
        }
      } else resolve();
    });
  });
};

const getS3Bucket = (userSession) => {
  return new AWS.S3({
    params: { Bucket: userSession.uploadBucketName },
    maxRetries: 9999,
    retryDelayOptions: {
      base: 100,
    },
    sslEnabled: true,
    useAccelerateEndpoint: true,
  });
};
