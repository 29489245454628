import request from "superagent";
import {
  processServiceResponse,
  getAuthHeaders,
  getBaseUrl,
  logDebug,
} from "./ServiceUtil";
import { showAccessRequestModalDispatcher } from "../redux/action/UIActions";

export default class FetchEntityService {
  /** override the URL if necessary: */
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }
  /**
   * Constructs a url like /entityTypeName/entityId/action?queryObject
   * @param {*} entityTypeName
   * @param {*} entityId
   * @param {*} action
   * @param {*} queryObject
   */
  fetchEntity(
    dispatch,
    getState,
    entityTypeName,
    entityId,
    action,
    queryObject = {}
  ) {
    return new Promise(async (resolve, reject) => {
      let url = this.baseUrl ? this.baseUrl : `${getBaseUrl(getState)}/api/v1`;
      url = entityTypeName ? `${url}/${entityTypeName}` : url;
      url = entityId ? `${url}/${entityId}` : url;
      url = action ? `${url}/${action}` : url;
      logDebug("fetchEntity", { url });
      const authHeaders = await getAuthHeaders(getState);
      request
        .get(url)
        .query(queryObject)
        .set(authHeaders)
        .set("Accept", "application/json")
        .end((error, response) => {
          processServiceResponse(
            dispatch,
            getState,
            error,
            response,
            resolve,
            reject
          );
          if (
            dispatch &&
            response &&
            response.statusCode === 403 &&
            response.body &&
            response.body.errorCode === "NoAccess"
          ) {
            dispatch(
              showAccessRequestModalDispatcher(
                entityTypeName,
                entityId,
                queryObject
              )
            );
          }
        });
    });
  }
}

export const fetchEntityService = new FetchEntityService();
