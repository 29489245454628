import { Typography } from "@mui/material";
import React, { Component } from "react";

const Loader = ({ title }) => {
  return (
    <div className="px-loader-wrapper">
      <div className="px-cssload-loader"></div>
      {title && (
        <Typography sx={{ mt: "15px" }} variant="h4">
          {title}
        </Typography>
      )}
    </div>
  );
};

export default Loader;
