import { useCallback, useEffect, useRef, useState } from "react";
import { isTestAgent, isWWW, queryObjectFromSearchString } from "./util/Utils";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  gettingStartedRideAction,
  gettingStartedRideDispatcher,
} from "./redux/action/GettingStartedRideActions";
import {
  blurCameraBackground,
  drawBackgroundGradient,
  drawCameraBubble,
  getCameraFillDimensions,
  initSegmenter,
  replaceCameraBackground,
  waitUntilVideoIsReady,
} from "./util/CameraCompositeUtil";
import {
  RecorderOptions,
  setRecorderOptionAction,
} from "./redux/action/RecorderActions";
import { backgroundGradientList } from "./component/screenRecorder/RecorderBackgroundChooser";

export const useScript = ({
  src,
  async = true,
  type = "text/javascript",
  textContent,
}) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = src;
    script.async = true;
    script.textContent = textContent;
    script.type = type;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [src]);
};

export const useAnalytics = () => {
  useEffect(() => {
    if (!isTestAgent()) {
      const script = document.createElement("script");
      script.src = "/static/js/analytics.js";
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);
};

export const useStateCallback = (initialState) => {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null); // init mutable ref container for callbacks

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb; // store current, passed callback in ref
    setState(state);
  }, []); // keep object reference stable, exactly like `useState`

  useEffect(() => {
    // cb.current is `null` on initial render,
    // so we only invoke callback on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
};

export const useLocationSearch = () => {
  const { search } = useLocation();
  return queryObjectFromSearchString(search);
};

export const useGettingStartedRideNextStep = (rideKeyList = []) => {
  const [isCallbackUsed, setIsCallbackUsed] = useState(false);

  const { gettingStartedRide } = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsCallbackUsed(false);
  }, [gettingStartedRide?.rideKey]);

  const nextHandler = () => {
    if (
      !isCallbackUsed &&
      gettingStartedRide &&
      rideKeyList.includes(gettingStartedRide.rideKey)
    ) {
      setIsCallbackUsed(true);
      dispatch(
        gettingStartedRideAction(
          gettingStartedRide.rideKey,
          gettingStartedRide.stepIndex + 1
        )
      );
    }
  };
  return nextHandler;
};

export const useGetElementById = (elementId, scrollIntoView) => {
  const [element, setElement] = useState(null);
  const timeout = useRef(null);
  const observer = useRef(null);

  useEffect(() => {
    if (elementId) getElementById(elementId);
    else setElement(null);
    return () => {
      if (observer.current) observer.current.disconnect();
      observer.current = null;
      if (timeout.current) clearTimeout(timeout.current);
      setElement(null);
    };
  }, [elementId]);

  const getElementById = (elementId) => {
    const el = document.getElementById(elementId);
    if (el) {
      observer.current = onElementRemoved(el, () => setElement(null));
      setElement(el);
      if (scrollIntoView)
        el.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
    } else {
      setElement(null);
      timeout.current = setTimeout(() => getElementById(elementId), 250);
    }
  };

  return element;
};

const onElementRemoved = (element, callback) => {
  const observer = new MutationObserver(function (mutations_list) {
    mutations_list.forEach(function (mutation) {
      mutation.removedNodes.forEach(function (removed_node) {
        if (isDescendant(removed_node, element)) {
          callback();
          observer.disconnect();
        }
      });
    });
  });
  observer.observe(document.body, { subtree: true, childList: true });
  return observer;
};

const isDescendant = (parent, child) => {
  var node = child.parentNode;
  while (node != null) {
    if (node == parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

export const useCameraBackground = () => {
  const requestAnimationCallbackRef = useRef(null);
  const recorderWorkerRef = useRef(null);
  const dispatch = useDispatch();

  const recorderWorkerEventListener = (event) => {
    const {
      messageType = "",
      recorderOptions = {},
      requester,
    } = event.data ?? {};
    switch (messageType) {
      case "recorderAnimationStep":
        if (requester === "ScreenRecorderControls")
          requestAnimationCallbackRef.current &&
            requestAnimationCallbackRef.current();
        break;
    }
  };

  useEffect(() => {
    if (typeof Worker !== "undefined" && !isWWW) {
      recorderWorkerRef.current = new Worker("/static/js/RecorderWorker.js");
      recorderWorkerRef.current.addEventListener(
        "message",
        recorderWorkerEventListener
      );
      return () =>
        recorderWorkerRef.current.removeEventListener(
          "message",
          recorderWorkerEventListener
        );
    }
  }, []);

  /**   videoRef->blurCameraBackground->renderCanvasRef->displayCanvasRef (video displayed on the DOM)
   */
  const renderBackground = async (
    cameraVideo,
    renderCanvas,
    displayCanvas,
    blurBackground = false,
    backgroundGradientName,
    aspectRatio = 1.7777,
    showBubble
  ) => {
    if (cameraVideo) {
      await waitUntilVideoIsReady(cameraVideo);

      const cameraDimensions = getCameraFillDimensions(
        cameraVideo.videoWidth,
        cameraVideo.videoHeight,
        showBubble ? 1 : aspectRatio
      );
      renderCanvas.width = cameraDimensions.tooWide
        ? cameraDimensions.height * aspectRatio
        : cameraVideo.videoWidth;
      renderCanvas.height = cameraDimensions.tooWide
        ? cameraDimensions.height
        : cameraVideo.videoWidth / aspectRatio;
      displayCanvas.width = renderCanvas.width;
      displayCanvas.height = renderCanvas.height;

      dispatch(
        setRecorderOptionAction(
          RecorderOptions.cameraRenderCanvasId,
          renderCanvas.id
        )
      );
      if (
        blurBackground ||
        (backgroundGradientName && backgroundGradientName !== "none")
      )
        await initSegmenter();
      const renderCanvasCtx = renderCanvas.getContext("2d", {
        alpha: true, //important since it's shared with CameraComposite Util!!!
      });
      const doRender = async () => {
        if (showBubble) {
          const gradientCanvas = drawBackgroundGradient(
            backgroundGradientName || "white",
            renderCanvas.width,
            renderCanvas.height
          );
          renderCanvasCtx.drawImage(gradientCanvas, 0, 0);
          const cameraCanvas = await drawCameraBubble(
            cameraVideo,
            cameraDimensions.width,
            cameraDimensions.height,
            blurBackground
          );
          const renderDimensions = getCameraFillDimensions(
            renderCanvas.width,
            renderCanvas.height,
            1
          );
          renderCanvasCtx.drawImage(
            cameraCanvas,
            0,
            0,
            cameraDimensions.width,
            cameraDimensions.height,
            renderDimensions.x,
            renderDimensions.y,
            cameraDimensions.width,
            cameraDimensions.height
          );
        } else if (
          backgroundGradientName &&
          backgroundGradientName !== "none"
        ) {
          const cameraCanvas = await replaceCameraBackground(
            cameraVideo,
            backgroundGradientName
          );
          const renderDimensions = getCameraFillDimensions(
            renderCanvas.width,
            renderCanvas.height,
            aspectRatio
          );
          renderCanvasCtx.drawImage(
            cameraCanvas,
            cameraDimensions.x,
            cameraDimensions.y,
            cameraDimensions.width,
            cameraDimensions.height,
            0,
            0,
            renderDimensions.width,
            renderDimensions.height
          );
        } else if (blurBackground) {
          const blurCanvas = await blurCameraBackground(cameraVideo);
          renderCanvasCtx.drawImage(
            blurCanvas,
            cameraDimensions.x,
            cameraDimensions.y,
            cameraDimensions.width,
            cameraDimensions.height,
            0,
            0,
            renderCanvas.width,
            renderCanvas.height
          );
        } else {
          renderCanvasCtx.drawImage(
            cameraVideo,
            cameraDimensions.x,
            cameraDimensions.y,
            cameraDimensions.width,
            cameraDimensions.height,
            0,
            0,
            renderCanvas.width,
            renderCanvas.height
          );
        }
        const displayCanvasCtx = displayCanvas.getContext("2d", {
          alpha: false,
        });
        displayCanvasCtx.drawImage(
          renderCanvas,
          0,
          0,
          renderCanvas.width,
          renderCanvas.height,
          0,
          0,
          displayCanvas.width,
          displayCanvas.height
        );
        recorderWorkerRef.current.postMessage({
          messageType: "requestAnimationFrame",
          timeout: 30,
          requester: "ScreenRecorderControls",
        });
      };
      requestAnimationCallbackRef.current = doRender;
      if (backgroundGradientName || blurBackground) initSegmenter();
      doRender();
    }
  };
  const stopBackgroundRender = () => {
    requestAnimationCallbackRef.current = null;
  };
  return { renderBackground, stopBackgroundRender };
};
