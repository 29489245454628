/* global chrome */

import { logError } from "./ServiceUtil";

const EXTENSION_ID = "hjofmchdijcfiokikfkknhkfkmbldmeg";
export const EXTENSION_SIGN_IN_PATH = "/extensionSignIn";
export const EXTENSION_OPEN_HASH = "#pixelmixer";

const isChrome =
  typeof navigator !== "undefined" &&
  /Chrome/.test(navigator.userAgent) &&
  /Google Inc/.test(navigator.vendor);

export const MessageType = {
  OpenScreenRecorder: "OpenScreenRecorder",
  SyncLocalStorageFromPixelMixer: "SyncLocalStorageFromPixelMixer",
};

export const sendMessage = async (message, messageType) => {
  return (
    isChrome &&
    (await new Promise(async (resolve, reject) => {
      try {
        if (isChrome && chrome.runtime)
          chrome.runtime.sendMessage(
            EXTENSION_ID,
            { ...message, messageType },
            (response) => resolve(response)
          );
        else console.warn("PixelMixer Chrome Extension not installed.");
      } catch (e) {
        logError("sendMessage", {}, e);
        reject(e.message);
      }
    }))
  );
};

export const openScreenRecorder = async () => {
  return (
    isChrome &&
    (await sendMessage({ show: true }, MessageType.OpenScreenRecorder))
  );
};

export const syncExtensionStorageFromPixelMixer = () => {
  try {
    isChrome && sendMessage({}, MessageType.SyncLocalStorageFromPixelMixer);
  } catch (e) {}
};
