import request from "superagent";
import {
  processServiceResponse,
  getAuthHeaders,
  getBaseUrl,
} from "./ServiceUtil";
import { removeEmptyElements } from "../util/Utils";

export default class UpdateEntityService {
  /**
   * Generates a url like this /api/v1/<entityTypeName>/<entityId>/<action>
   * @param {*} entityTypeName
   * @param {*} entityId
   * @param {*} formObject
   * @param {*} action
   */
  updateEntity(
    dispatch,
    getState,
    entityTypeName,
    entityId = null,
    formObject = {},
    action,
    queryObject = {}
  ) {
    return new Promise(async (resolve, reject) => {
      let putUrl = `${getBaseUrl(getState)}/api/v1`;
      putUrl = entityTypeName !== null ? `${putUrl}/${entityTypeName}` : putUrl;
      putUrl = entityId !== null ? `${putUrl}/${entityId}` : putUrl;
      putUrl = action ? `${putUrl}/${action}` : putUrl;
      const authHeaders = await getAuthHeaders(getState);
      request
        .put(putUrl)
        .query(queryObject)
        .set(authHeaders)
        .set("Accept", "application/json")
        .type("application/x-www-form-urlencoded")
        .send(removeEmptyElements(formObject))
        .end((error, response) => {
          processServiceResponse(
            dispatch,
            getState,
            error,
            response,
            resolve,
            reject
          );
        });
    });
  }
  
  updateEntityWithJson(
    dispatch,
    getState,
    entityTypeName,
    entityId = null,
    jsonBody = {},
    action,
    queryObject = {}
  ) {
    return new Promise(async (resolve, reject) => {
      let putUrl = `${getBaseUrl(getState)}/api/v1`;
      putUrl = entityTypeName !== null ? `${putUrl}/${entityTypeName}` : putUrl;
      putUrl = entityId !== null ? `${putUrl}/${entityId}` : putUrl;
      putUrl = action ? `${putUrl}/${action}` : putUrl;
      const authHeaders = await getAuthHeaders(getState);
      request
        .put(putUrl)
        .query(queryObject)
        .set(authHeaders)
        .set("Accept", "application/json")
        .send(jsonBody)
        .end((error, response) => {
          processServiceResponse(
            dispatch,
            getState,
            error,
            response,
            resolve,
            reject
          );
        });
    });
  }
}

export var updateEntityService = new UpdateEntityService();
