/*global chrome*/
import { RecorderMethod } from "../../component/screenRecorder/Recorder";

import { trackEvent } from "./CreateEntityActions";
import { showUIComponentAction, UIComponent } from "./UIActions";

export const RecorderOptions = {
  backgroundGradientName: "backgroundGradientName",
  cameraId: "cameraId",
  cameraName: "cameraName",
  cameraRenderCanvasId: "cameraRenderCanvasId",
  contentId: "contentId",
  micId: "micId",
  muteCamera: "muteCamera",
  muteMic: "muteMic",
  recordDesktop: "recordDesktop",
  recordTab: "recordTab",
  recordTabAudio: "recordTabAudio",
  recorderStatus: "recorderStatus",
  blurBackground: "blurBackground",
  flipCamera: "flipCamera",
  hideTimer: "hideTimer",
  baseUrl: "baseUrl",
  pipOffset: "pipOffset",
  showBubble: "showBubble",
  startTime: "startTime",
  skipCountdown: "skipCountdown",
  uploadSessionId: "uploadSessionId",
  videoSize: "videoSize",
};

export const COUNTDOWN_TIME = 5000;

let recordingTimeout = false;

export const setRecorderOptions = (recorderOptions) => {
  return {
    type: "recorderOptions",
    recorderOptions,
  };
};

export const setRecorderOptionAction = (option, value) => {
  const recorderOptions = {};
  recorderOptions[option] = value;
  return {
    type: "recorderOptions",
    recorderOptions,
  };
};

export const recorderMethodAction = (method, recorderOptions = {}) => {
  return {
    type: "recorderMethod",
    method,
    recorderOptions,
  };
};

export const setRecordedFileAction = (recordedFile) => {
  return {
    type: "recordedFile",
    recordedFile,
  };
};

export const setPipDimensionsAction = (pipDimensions) => {
  return {
    type: "recorderPipDimensions",
    pipDimensions,
  };
};

export const setScreenSizeAction = (screenSize = { width: 0, height: 0 }) => {
  return {
    type: "recorderScreenSize",
    screenSize: {
      width: parseInt(screenSize.width / 2) * 2,
      height: parseInt(screenSize.height / 2) * 2,
    },
  };
};

export const showCameraRecorderDispatcher = (recorderOptions) => {
  return (dispatch, getState) => {
    if (recorderOptions) dispatch(setRecorderOptions(recorderOptions));
    dispatch(showUIComponentAction(UIComponent.CameraRecorderDrawer, true));
    dispatch(showUIComponentAction(UIComponent.RecorderDrawer, true));
    dispatch(showUIComponentAction(UIComponent.RecorderControls, true));
    trackEvent("showCameraRecorder");
  };
};

export const showRecorderDispatcher = (recorderOptions) => {
  return (dispatch, getState) => {
    if (recorderOptions) dispatch(setRecorderOptions(recorderOptions));
    dispatch(showUIComponentAction(UIComponent.RecorderDrawer, true));
    dispatch(showUIComponentAction(UIComponent.RecorderControls, true));
    trackEvent("showRecorder");
  };
};

export const resetRecorderDispatcher = () => {
  return (dispatch, getState) => {
    if (recordingTimeout) clearTimeout(recordingTimeout);
    dispatch(showUIComponentAction(UIComponent.Countdown, false));
    dispatch(showUIComponentAction(UIComponent.RecorderDrawer, true));
  };
};

export const resetCameraRecorderDrawerDispatcher = () => {
  return (dispatch, getState) => {
    dispatch(showUIComponentAction(UIComponent.RecorderDrawer, true));
    dispatch(
      setRecorderOptionAction(RecorderOptions.backgroundGradientName, null)
    );
  };
};

export const doneRecordingDispatcher = () => {
  return (dispatch, getState) => {
    if (recordingTimeout) clearTimeout(recordingTimeout);
    dispatch(showUIComponentAction(UIComponent.Countdown, false));
    dispatch(setRecorderOptionAction(RecorderOptions.startTime, false));
  };
};

export const startRecordingCountdownDispatcher = () => {
  return (dispatch, getState) => {
    dispatch(showUIComponentAction(UIComponent.RecorderControls, true));
    dispatch(showUIComponentAction(UIComponent.RecorderDrawer, false));
    if (getState().recorder.recorderOptions[RecorderOptions.skipCountdown]) {
      dispatch(recorderMethodAction(RecorderMethod.startRecording));
    } else {
      dispatch(showUIComponentAction(UIComponent.Countdown, true));
      recordingTimeout = setTimeout(() => {
        dispatch(showUIComponentAction(UIComponent.Countdown, false));
        recordingTimeout = setTimeout(() => {
          dispatch(recorderMethodAction(RecorderMethod.startRecording));
        }, 10);
      }, COUNTDOWN_TIME - 10);
    }
  };
};

export const initRecorderDispatcher = () => {
  return (dispatch, getState) => {
    const { recorderOptions } = getState().recorder;
    dispatch(recorderMethodAction(RecorderMethod.doInit, recorderOptions));
  };
};

export const stopRecordingDispatcher = () => {
  return (dispatch, getState) => {
    dispatch(recorderMethodAction(RecorderMethod.stopRecording));
    dispatch(resetRecorderDispatcher());
    dispatch(showUIComponentAction(UIComponent.Countdown, false));
    dispatch(showUIComponentAction(UIComponent.RecorderControls, false));
    dispatch(showUIComponentAction(UIComponent.RecorderDrawer, false));
    dispatch(showUIComponentAction(UIComponent.CameraRecorderDrawer, false));
    dispatch(setRecorderOptionAction(RecorderOptions.startTime, false));
  };
};

export const cancelRecordingDispatcher = () => {
  return (dispatch, getState) => {
    dispatch(recorderMethodAction(RecorderMethod.cancelRecording));
    dispatch(resetRecorderDispatcher());
  };
};

export const closeRecorderDispatcher = () => {
  return (dispatch, getState) => {
    dispatch(showUIComponentAction(UIComponent.RecorderDrawer, false));
    dispatch(showUIComponentAction(UIComponent.CameraRecorderDrawer, false));
    dispatch(showUIComponentAction(UIComponent.RecorderControls, false));
  };
};
