import React, { Component, useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { connect } from "react-redux";
import { Card, CardContent, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const FormModal = ({
  open,
  onHide,
  dialogId,
  dialogContentClassName,
  form,
  title,
  hideOnBlur = true,
  logoUrl,
  fullScreen,
  fullWidth,
  playerOnly,
  maxWidth = false,
}) => {
  const [renderDialog, setRenderDialog] = useState(open);
  const renderTimeoutRef = useRef();

  //TODO: This is a fix for the UI unresponsive bug and can be removed once it is resolved: https://github.com/mui/material-ui/issues/32286
  useEffect(() => {
    if (!open) {
      renderTimeoutRef.current = setTimeout(() => {
        setRenderDialog(false);
        renderTimeoutRef.current = false;
      }, 1000);
    } else {
      setRenderDialog(true);
      if (renderTimeoutRef.current) {
        clearTimeout(renderTimeoutRef.current);
        renderTimeoutRef.current = false;
      }
    }
  }, [open]);
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      {renderDialog && (
        <Dialog
          scroll="body"
          fullScreen={fullScreen}
          maxWidth={maxWidth}
          fullWidth={fullWidth}
          open={open}
          onClose={(e, reason) => {
            if (onHide && (hideOnBlur || reason === "escapeKeyDown")) onHide();
          }}
          aria-labelledby="form-modal-title"
          id={dialogId}
          // PaperComponent={PaperComponent}
        >
          <div className="form-modal-content">
            <DialogTitle className="form-modal-title">
              {title}
              {!!onHide && (
                <IconButton
                  key="close"
                  color="primary"
                  aria-label="close"
                  onClick={(e) => {
                    e.preventDefault();
                    onHide();
                  }}
                >
                  <CloseIcon color="primary" style={{ fontSize: 18 }} />
                </IconButton>
              )}
            </DialogTitle>
            <DialogContent
              classes={{
                root: dialogContentClassName,
              }}
              sx={{
                overFlowY: "auto",
                maxHeight: `calc(100vh - ${fullScreen ? 81 : 143}px)`,
                padding: 0,
              }}
            >
              <Card>
                <CardContent>{form}</CardContent>
              </Card>

              {!matchesXs && !playerOnly && (
                <div className="form-logo-container">
                  <img src={logoUrl} alt="logo" className="form-logo" />
                </div>
              )}
            </DialogContent>
          </div>
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { deviceAPI, playerOnly } = state.ui;
  const account = state.current.entity.account;
  return {
    fullScreen: deviceAPI.isPhone,
    logoUrl: account?.logoUrl || "/static/img/pixelmixer-logo.png",
    playerOnly,
  };
};

export default connect(mapStateToProps, () => ({}))(FormModal);
