import "@tensorflow/tfjs-backend-webgl";
import * as bodySegmentation from "@tensorflow-models/body-segmentation";
import * as mpSelfieSegmentation from "@mediapipe/selfie_segmentation";
import { backgroundGradientList } from "../component/screenRecorder/RecorderBackgroundChooser";
import { logDebug, logError } from "../service/ServiceUtil";

let segmenter;

export const initSegmenter = async () => {
  if (!segmenter) {
    segmenter = await bodySegmentation.createSegmenter(
      bodySegmentation.SupportedModels.MediaPipeSelfieSegmentation,
      {
        runtime: "mediapipe", // mediapipe or 'tfjs'
        solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation@${mpSelfieSegmentation.VERSION}`,
        modelType: "general",
      }
    );
  }
};

export const waitUntilVideoIsReady = async (inputVideo) => {
  if (inputVideo.readyState < 2) {
    logDebug("video.readyState not ready", inputVideo.readyState);
    return await new Promise((resolve) => {
      inputVideo.onloadeddata = () => {
        logDebug("finally video.readyState", inputVideo.readyState);
        resolve(inputVideo);
      };
    });
  } else return inputVideo;
};

export const blurCameraBackground = async (inputVideo) => {
  try {
    await waitUntilVideoIsReady(inputVideo);
    if (
      (segmenter.segmentPeople,
      {
        flipHorizontal: true,
      })
    ) {
      const segmentation = await segmenter.segmentPeople(inputVideo);
      const offScreenCanvas = new OffscreenCanvas(
        inputVideo.videoWidth,
        inputVideo.videoHeight
      );
      await bodySegmentation.drawBokehEffect(
        offScreenCanvas,
        inputVideo,
        segmentation,
        0.85 /* Foreground Threshold */,
        10 /* Blur Amount */,
        10 /* Edge Blur */
      );
      return offScreenCanvas;
    } else logError("segmenter.segmentPeople not ready..");
  } catch (e) {
    logError("blurCameraBackground", {}, e);
    segmenter = null;
  }
};

export const replaceCameraBackground = async (
  inputVideo,
  backgroundGradientName
) => {
  const foregroundColor = { r: 0, g: 255, b: 0, a: 255 };
  const backgroundColor = { r: 0, g: 0, b: 0, a: 0 };
  const drawContour = false;
  const foregroundThreshold = 0.99;
  try {
    await waitUntilVideoIsReady(inputVideo);
    if (segmenter.segmentPeople) {
      const segmentation = await segmenter.segmentPeople(inputVideo, {
        flipHorizontal: false,
      });
      const binaryMask = await bodySegmentation.toBinaryMask(
        segmentation,
        foregroundColor,
        backgroundColor,
        drawContour,
        foregroundThreshold
      );
      const maskCanvas = new OffscreenCanvas(
        binaryMask.width,
        binaryMask.height
      );
      const maskCanvasContext = maskCanvas.getContext("2d", {
        alpha: true,
      });
      maskCanvasContext.putImageData(binaryMask, 0, 0);

      const compositeCanvas = new OffscreenCanvas(
        binaryMask.width,
        binaryMask.height
      );
      const compositeCanvasContext = compositeCanvas.getContext("2d", {
        alpha: true,
      });
      compositeCanvasContext.filter = "blur(7px)";
      compositeCanvasContext.drawImage(maskCanvas, 0, 0);
      compositeCanvasContext.filter = "none";
      compositeCanvasContext.globalCompositeOperation = "source-in";
      compositeCanvasContext.filter = "brightness(120%) saturate(110%)";
      compositeCanvasContext.drawImage(inputVideo, 0, 0);
      compositeCanvasContext.filter = "none";

      const outputCanvas = new OffscreenCanvas(
        inputVideo.videoWidth,
        inputVideo.videoHeight
      );
      const outputCanvasContext = outputCanvas.getContext("2d", {
        alpha: true,
      });
      const gradientCanvas = drawBackgroundGradient(
        backgroundGradientName,
        outputCanvas.width,
        outputCanvas.height
      );
      outputCanvasContext.drawImage(gradientCanvas, 0, 0);
      outputCanvasContext.drawImage(compositeCanvas, 0, 0);
      // await bodySegmentation.(
      //   outputCanvas,
      //   inputVideo,
      //   offscreenCanvasContext.getImageData(
      //     0,
      //     0,
      //     offscreenCanvas.width,
      //     offscreenCanvas.height
      //   ),
      //   1,
      //   3,
      //   false
      // );
      return outputCanvas;
    } else logError("segmenter.segmentPeople not ready..");
  } catch (e) {
    logError("replaceCameraBackground", {}, e);
    segmenter = null;
  }
};

export const drawBackgroundGradient = (
  backgroundGradientName,
  width,
  height
) => {
  const gradientCanvas = new OffscreenCanvas(width, height);
  const gradientCanvasContext = gradientCanvas.getContext("2d", {
    alpha: true,
  });
  const backgroundGradient = backgroundGradientList[backgroundGradientName];
  const gradient = gradientCanvasContext.createLinearGradient(
    0,
    0,
    0,
    gradientCanvas.height
  );
  for (const colorObject of backgroundGradient.colors) {
    for (const [position, color] of Object.entries(colorObject)) {
      gradient.addColorStop(parseFloat(position), color);
    }
  }
  // Fill with gradient
  gradientCanvasContext.fillStyle = gradient;
  gradientCanvasContext.fillRect(
    0,
    0,
    gradientCanvas.width,
    gradientCanvas.height
  );
  return gradientCanvas;
};

export const drawCameraBubble = async (
  cameraVideo,
  width,
  height,
  blurBackground
) => {
  const outputCanvas = new OffscreenCanvas(width, height);
  //1. Draw and crop video inside canvas:
  const cameraDimensions = getCameraFillDimensions(
    cameraVideo.videoWidth,
    cameraVideo.videoHeight,
    1
  );
  const cameraCanvas = new OffscreenCanvas(
    cameraDimensions.width,
    cameraDimensions.height
  );
  const cameraCanvasContext = cameraCanvas.getContext("2d", {
    alpha: true,
  });

  //Clipping path:
  cameraCanvasContext.clearRect(
    0,
    0,
    cameraDimensions.width,
    cameraDimensions.height
  );
  const videoRadius = cameraDimensions.height / 2;
  cameraCanvasContext.beginPath();
  cameraCanvasContext.arc(
    cameraDimensions.width / 2,
    cameraDimensions.height / 2,
    videoRadius,
    0,
    2 * Math.PI
  );
  cameraCanvasContext.clip();
  
  let sourceVideo = cameraVideo;
  if (blurBackground) {
    const blurCanvas = await blurCameraBackground(cameraVideo);
    sourceVideo = blurCanvas;
  }
  cameraCanvasContext.drawImage(
    sourceVideo,
    cameraDimensions.x,
    cameraDimensions.y,
    cameraDimensions.width,
    cameraDimensions.height,
    0,
    0,
    cameraDimensions.width,
    cameraDimensions.height
  );
  //2. Draw white outline
  const outputCanvasContext = outputCanvas.getContext("2d", {
    alpha: true,
  });
  outputCanvasContext.fillStyle = "white";
  outputCanvasContext.beginPath();
  const radius = outputCanvas.height / 2 + 2;
  outputCanvasContext.arc(
    outputCanvas.width / 2,
    outputCanvas.height / 2,
    radius,
    0,
    2 * Math.PI
  );
  outputCanvasContext.fill();
  outputCanvasContext.drawImage(cameraCanvas, 0, 0);
  return outputCanvas;
};

export const getCameraFillDimensions = (sW, sH, aspectRatio) => {
  const tooWide = sW / sH > aspectRatio;
  const width = tooWide ? sH * aspectRatio : sW;
  const height = tooWide ? sH : sW / aspectRatio;
  const x = (sW - width) / 2;
  const y = (sH - height) / 2;
  return { x, y, width, height, tooWide };
};
