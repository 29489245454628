import "aws-sdk/dist/aws-sdk";
import { fetchListService } from "../../service/FetchListService";
import { EntityType } from "./CurrentActions";
import { deleteUploadDispatcher } from "./DeleteEntityActions";
import { setRecordedFileAction } from "./RecorderActions";
import { bootstrapUserSessionAndLoginStateDispatcher } from "./LoginActions";
import { logError, logInfo } from "../../service/ServiceUtil";
import { fetchEntityService } from "../../service/FetchEntityService";

const AWS = typeof window !== "undefined" && window.AWS;
if (AWS) AWS.config.region = "us-west-1";

export const LOCAL_STORAGE_UPLOAD_KEY = "pixelMixerUploadFileRequest";

export const UploadStatus = {
  NONE: "Awaiting upload",
  UPLOADING: "Uploading",
  PREPARING: "Analyzing",
  PENDING: "Queueing",
  QUEUED: "Queued",
  IN_PROCESS: "Processing",
  PUBLISHING: "Publishing",
  SUCCESS: "Online",
  CANCELLED: "User Cancelled",
  UPLOAD_ERROR: "Upload error",
  FAILURE: "Processing error",
};

export const getActiveUploadCount = (getState) =>
  getState().uploadQueue.fileUploadArray.reduce((activeCount, fileUpload) => {
    return fileUpload.queued || fileUpload.isActive
      ? activeCount + 1
      : activeCount;
  }, 0);

export const queueFileUploadAction = (file, upload, posterUrl) => {
  return {
    type: "QUEUE_FILE_UPLOAD_ACTION",
    file,
    upload,
    posterUrl,
  };
};

export const cancelFileUploadDispatcher = (fileUploadId) => {
  return (dispatch, getState) => {
    const fileUploadList = getState().uploadQueue.fileUploadArray;
    for (const fileUpload of fileUploadList) {
      try {
        if (fileUpload.id === fileUploadId) {
          if (fileUpload.s3Upload) fileUpload.s3Upload.abort();
          dispatch(removeFileUploadAction(fileUpload));
        }
      } catch (e) {}
    }
    dispatch(fileUploadProcessingDispatcher());
    dispatch(deleteUploadDispatcher(fileUploadId));
  };
};

export const removeFileUploadAction = (fileUpload) => {
  return {
    type: "REMOVE_FILE_UPLOAD_ACTION",
    fileUpload,
  };
};

export const updateFileUploadStatusAction = (
  fileUpload,
  uploadStatus,
  statusMessage
) => {
  return {
    type: "UPDATE_FILE_UPLOAD_STATUS_ACTION",
    fileUpload,
    uploadStatus,
    statusMessage,
  };
};

export const updateFileUploadStatusDispatcher = (
  fileUpload,
  uploadStatus,
  statusMessage
) => {
  return (dispatch, getState) => {
    dispatch(
      updateFileUploadStatusAction(fileUpload, uploadStatus, statusMessage)
    );
  };
};

export const updateFileUploadContentAction = (content) => {
  return {
    type: "UPDATE_FILE_UPLOAD_CONTENT_ACTION",
    content,
  };
};

export const refreshFileUploadListDispatcher = () => {
  return async (dispatch, getState) => {
    for (let fileUpload of getState().uploadQueue.fileUploadArray) {
      const upload = await fetchEntityService.fetchEntity(
        dispatch,
        getState,
        EntityType.Upload,
        fileUpload.id
      );
      // dispatch(updateUploadAction(upload));
    }
  };
};

export const updateUploadAction = (upload) => {
  return {
    type: "UPDATE_UPLOAD_ACTION",
    upload,
  };
};

export const clearFileUploadListAction = () => {
  return {
    type: "CLEAR_UPLOAD_LIST_ACTION",
  };
};

export const updateFileUploadProgressAction = (fileUpload, loaded, total) => {
  return {
    type: "UPDATE_FILE_UPLOAD_PROGRESS_ACTION",
    fileUpload,
    loaded,
    total,
  };
};

export const fetchRecentUploadsDispatcher = () => {
  return async (dispatch, getState) => {
    return await fetchListService.fetchList(
      dispatch,
      getState,
      "recentUploads",
      EntityType.Upload
    );
  };
};

export const mergeUploadListAction = (updatedUploadsList) => {
  return {
    type: "MERGE_UPLOAD_LIST_ACTION",
    updatedUploadsList,
  };
};

export const fileUploadProcessingDispatcher = () => {
  return (dispatch, getState) => {
    const userSession = getState().current.entity.userSession;
    const fileUploadArray = getState().uploadQueue.fileUploadArray;
    for (var i = 0; i < fileUploadArray.length; i++) {
      const fileUpload = fileUploadArray[i];
      const uploadStatusName = UploadStatus[fileUpload.uploadStatus];
      if (uploadStatusName === UploadStatus.UPLOADING) break;
      else if (
        userSession &&
        uploadStatusName === UploadStatus.NONE &&
        fileUpload.queued &&
        fileUpload.file
      ) {
        AWS.config.credentials = new AWS.Credentials(
          userSession.uploadAccessKey,
          userSession.uploadSecretKey,
          null
        );
        const s3Bucket = new AWS.S3({
          params: {
            Bucket: userSession.uploadBucketName,
            Tagging: "upload=true",
          },
          maxRetries: 9999,
          retryDelayOptions: {
            base: 100,
          },
          sslEnabled: true,
          useAccelerateEndpoint: true,
        });
        upload(dispatch, fileUpload, s3Bucket);
        break;
      }
    }
  };
};

const upload = (dispatch, fileUpload, s3Bucket) => {
  dispatch(updateFileUploadStatusDispatcher(fileUpload, "UPLOADING"));
  const params = {
    Key: fileUpload.uploadObjectKey,
    ContentType: fileUpload.file.type,
    Body: fileUpload.file,
  };
  const options = { partSize: 5 * 1024 * 1024, queueSize: 4 };
  fileUpload.s3Upload = s3Bucket.upload(params, options, (err, data) => {
    let uploadStatus = "UPLOAD_ERROR";
    let statusMessage = null;
    if (err !== null) {
      switch (err.code) {
        case "NetworkingError":
          statusMessage = "Not connected to the Internet";
          break;
        case "RequestAbortedError":
          uploadStatus = "CANCELLED";
          statusMessage = UploadStatus.CANCELLED;
          break;
        default:
          statusMessage = err.message;
      }
    } else {
      uploadStatus = "PREPARING";
    }
    dispatch(
      updateFileUploadStatusDispatcher(fileUpload, uploadStatus, statusMessage)
    );
    dispatch(fileUploadProcessingDispatcher());
  });
  fileUpload.s3Upload.on("httpUploadProgress", (progress) => {
    dispatch(
      updateFileUploadProgressAction(
        fileUpload,
        progress.loaded,
        progress.total
      )
    );
  });
};

export const configureLocalStorageUploadListenerDispatcher = () => {
  return (dispatch, getState) => {
    document.addEventListener(LOCAL_STORAGE_UPLOAD_KEY, (e) => {
      logInfo(LOCAL_STORAGE_UPLOAD_KEY, e);
      // Run through the bootstrap process in case the cognito
      // credentails were just transferred:
      dispatch(bootstrapUserSessionAndLoginStateDispatcher());
    });
  };
};

export const checkUploadFromLocalStorageDispatcher = () => {
  return async (dispatch, getState) => {
    const uploadFileRequest = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_UPLOAD_KEY)
    );
    if (
      uploadFileRequest &&
      getState().uploadQueue.fileUploadArray.filter(
        (fileUpload) => fileUpload.file?.url === uploadFileRequest.url
      ).length <= 0
    ) {
      try {
        const file = await fetch(uploadFileRequest.url).then((r) => r.blob());
        // We are no longer including recorderOptions from the extension so we know to hide the
        // re-record button for the sake of UX continuity
        Object.assign(file, uploadFileRequest);
        file.url = uploadFileRequest.url;
        file.name = uploadFileRequest.name;
        dispatch(setRecordedFileAction(file));
        localStorage.removeItem(LOCAL_STORAGE_UPLOAD_KEY);
      } catch (e) {
        logError("Unable to access file upload blob", uploadFileRequest, e);
      }
    }
  };
};
