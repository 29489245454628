import { logDebug, logError } from "./ServiceUtil";

class ReactWebSocketService {
  isConnected = false;
  reconnectIntervalInMilliSeconds = 3000;
  attempts = 1;
  shouldReconnect = true;
  debug = true;

  constructor(url) {
    this.setupWebSocket(url);
  }

  setupWebSocket(url) {
    this.shouldReconnect = true;
    this.webSocket = window.WebSocket
      ? new window.WebSocket(url)
      : new window.MozWebSocket(url);
    this.webSocket.onopen = () => {
      logDebug("webSocket connected", { date: new Date() });
      this.isConnected = true;
      this.attempts = 0;
      if (typeof this.onOpen !== "undefined") this.onOpen();
    };
    this.webSocket.onmessage = (evt) => {
      if (typeof this.onMessage !== "undefined") this.onMessage(evt.data);
    };
    this.webSocket.onerror = () => {
      this.webSocket.close();
      if (typeof this.onError !== "undefined") this.onError();
    };
    this.webSocket.onclose = () => {
      logDebug("webSocket disconnected", { date: new Date() });
      this.isConnected = false;
      if (typeof this.onClose !== "undefined") this.onClose();
      if (this.shouldReconnect) {
        let time = this.generateInterval();
        setTimeout(() => {
          this.attempts++;
          this.setupWebSocket(url);
        }, time);
      }
    };
  }

  send(object) {
    try {
      this.webSocket.send(JSON.stringify(object));
    } catch (e) {
      logError("Unable to send websocket message.", object, e);
    }
  }

  close() {
    this.shouldReconnect = false;
    try {
      this.webSocket.close();
    } catch (e) {
      logError("Unable to close websocket.", object, e);
    }
  }

  generateInterval() {
    return (
      Math.min(30, Math.pow(2, this.attempts) - 1) *
      this.reconnectIntervalInMilliSeconds
    );
  }
}

export default ReactWebSocketService;
